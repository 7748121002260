require('@less/uawg/main.less')

// get reference to external global var
var $ = jQuery // eslint-disable-line no-undef

$(function () {
  $.my.init.dateTimePickers()

  // deactivates css transitions
  // $.support.transition = undefined;

  // XXX switch all this to $.my.init system

  installAjaxLinks()

  // $('.parent-typeahead').parentTypeahead()

  $('div.message.success').delay(7000).hide('slide', {
    direction: 'right'
  }, 2000)

  highlightFulltextTerms()

  $('input[type=checkbox][name$="[birthdate_unknown]"]').birthdateUnknown()
})

